import { OutletListItem } from '@ancon/wildcat-types'
import clsx from 'clsx'
import {
  getOutletFormattedDistance,
  getOutletOrderFormats,
  getOutletDeliveryFee,
  getOutletFormattedAddress,
} from '@ancon/wildcat-utils/outlet'
import useTranslation from 'next-translate/useTranslation'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import OrderTypeIcon from '../../../app/components/OrderTypeIcon'
import isOutletsShowOutletBanner from '../../utils/isOutletsShowOutletBanner'
import isAppWhiteLabeled from '../../../app/utils/isAppWhiteLabeled'
import { AllOrderFormatsArray } from '../../constants'
import isOutletDeliveryFeeShown from '../../../outlet/utils/isOutletDeliveryFeeShown'

import OutletCardOutletStatus from './OutletCardOutletStatus'
import styles from './OutletCardOutletInfoSummary.module.scss'

interface OutletCardOutletInfoSummaryProps {
  outlet: OutletListItem
  showOutletStatus?: boolean
  className?: string
}

export default function OutletCardOutletInfoSummary({
  outlet,
  showOutletStatus = false,
  className,
}: OutletCardOutletInfoSummaryProps) {
  const { t } = useTranslation('outlets')

  const showOutletBanner = isOutletsShowOutletBanner()
  const isWhiteLabeled = isAppWhiteLabeled()

  const { name, address, serviceFees, distance } = outlet

  const outletAddress = getOutletFormattedAddress(address)
  const orderFormats = getOutletOrderFormats(outlet)
  const deliveryFee = getOutletDeliveryFee(serviceFees)

  const orderFormatsArray = isWhiteLabeled ? AllOrderFormatsArray : orderFormats

  const showDeliveryFee = isOutletDeliveryFeeShown(outlet)

  const cuisinesText = outlet?.cuisine?.join?.(', ') || ''

  function getFormattedDeliveryFee() {
    const deliveryFeeAmount = deliveryFee?.amount

    if (deliveryFeeAmount?.amount) {
      return getFormattedCurrency(
        deliveryFeeAmount.amount,
        deliveryFeeAmount.currency,
      )
    }

    return t('freeDelivery')
  }

  return (
    <div className={clsx(styles.container, className)}>
      {showOutletStatus && !showOutletBanner && (
        <OutletCardOutletStatus outlet={outlet} inline />
      )}
      <HeadingText
        as="h3"
        title={name}
        color={isWhiteLabeled ? 'heading-1' : 'heading-0'}
      >
        {name}
      </HeadingText>
      <BodyText
        className={styles.addressText}
        title={outletAddress}
        color={isWhiteLabeled ? 'body-1' : 'body-0'}
      >
        {outletAddress}
      </BodyText>
      {cuisinesText && (
        <BodyText className={styles.cuisineText} title={cuisinesText}>
          {cuisinesText}
        </BodyText>
      )}
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <BodyText>{getOutletFormattedDistance(distance)}</BodyText>
          {showDeliveryFee && (
            <BodyText>
              &nbsp; &middot; &nbsp;
              {getFormattedDeliveryFee()}
            </BodyText>
          )}
        </div>
        <div>
          {orderFormatsArray.map(orderFormat => (
            <OrderTypeIcon
              key={orderFormat}
              orderFormat={orderFormat}
              className={clsx(styles.orderFormat, {
                [styles.whiteLabeled]: isWhiteLabeled,
                [styles.available]: orderFormats.includes(orderFormat),
              })}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
