import useTranslation from 'next-translate/useTranslation'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import HandIcon from '@ancon/wildcat-ui/shared/icons/hand-click-outline.svg'
import { OrderFormat } from '@ancon/wildcat-types'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import Card from '../../../layout/components/card/Card'
import {
  outletDetailsSelector,
  outletIsOutletDetailsPendingSelector,
} from '../../../outlet/store/outletSelector'
import { outletsSelectedOutletListItemSelector } from '../../store/outletsSelectors'
import localStorageUtils from '../../../app/utils/localStorageUtils'

import styles from './NewOrderCard.module.scss'
import NewOrderOutletDetails from './NewOrderOutletDetails'
import NewOrderOutletDetailsSkeleton from './NewOrderOutletDetailsSkeleton'

type NewOrderCardProps = {
  isInModal?: boolean
  onClickOrderFormat: (orderFormat: OrderFormat) => void
}

export default function NewOrderCard({
  isInModal,
  onClickOrderFormat,
}: NewOrderCardProps) {
  const { t } = useTranslation('outlets')
  const outletDetails = useAppSelector(outletDetailsSelector)
  const outletListItem = useAppSelector(outletsSelectedOutletListItemSelector)
  const outletDetailsFetchPending = useAppSelector(
    outletIsOutletDetailsPendingSelector,
  )

  function renderContent() {
    const preSelectedOutletId = localStorageUtils.getItem<string>(
      'preSelectedOutletId',
    )

    if (preSelectedOutletId) {
      const isOutletDataReady = !!outletDetails && !!outletListItem

      if (!outletDetailsFetchPending && isOutletDataReady) {
        return (
          <NewOrderOutletDetails
            isInModal={isInModal}
            outletDetails={outletDetails}
            onClickOrderFormat={onClickOrderFormat}
          />
        )
      }

      return <NewOrderOutletDetailsSkeleton />
    }

    return (
      <div className={styles.placeholderWrapper}>
        <HandIcon />
        <BodyText>{t('newOrderCard.selectYourRestaurant')}</BodyText>
      </div>
    )
  }

  if (isInModal) {
    return renderContent()
  }

  return (
    <Card className={styles.container} tabIndex={0}>
      {renderContent()}
    </Card>
  )
}
